<template>
	<section class="home-page">
		<header class="header">
			<TopNavbar
				@clicked="sideNavEvent"
				:navFlag="navFlag"
				:name="name"
				:profile_pic="profilePic"
				:role="role"
			/>
		</header>
	</section>

	<section class="skil-matrix">
		<div class="task-intro container">
			<div style="float: right" v-if="role === 'manager'">
				<el-button type="primary" icon="el-icon-user" @click="teamMemberPage"
					>Team Members</el-button
				>
			</div>
			<h2 class="heading" style="text-align: center">Team Member Appraisal</h2>
			<el-divider></el-divider>
			<p>
				RATING GUIDELINES:
				<a
					href="https://docs.google.com/spreadsheets/d/1YucTtZk-CGB8TrcoyiukjBO1glCqS4FIMkFoWNBBiq0/edit?usp=sharing"
					>https://docs.google.com/spreadsheets/d/1YucTtZk-CGB8TrcoyiukjBO1glCqS4FIMkFoWNBBiq0/edit?usp=sharing</a
				>
			</p>
		</div>
	</section>
	<section>
		<div class="container col-md-12">
			<el-card class="box-card" v-if="!is_submitted && !finalSubmission">
				<el-form
					class="matrix-form"
					id="matrix-form"
					:rules="rules"
					ref="skillMatrixFormData"
					@submit="$event.preventDefault()"
					@keypress.enter="__storeTeamEvaluation($event)"
				>
					<div class="row" v-for="quest in questions" :key="quest">
						<div class="column" style="width: 45%">
							<div>
								{{ quest.question }}
							</div>
						</div>
						<div class="column" style="text-align: center; margin-right: 40px">
							<el-form-item>
								<el-rate
									v-model="selfEvaluationData.rating[quest.id]"
									:texts="points"
									show-text
									size="large"
								/>
							</el-form-item>
						</div>
						<div class="column" style="width: 33%">
							<el-form-item>
								<el-input
									v-model="selfEvaluationData.comment[quest.id]"
									type="textarea"
									placeholder="Comments"
								/>
							</el-form-item>
						</div>
					</div>
					<!-- Action btn -->
					<div class="flex" style="text-align-last: center">
						<el-button
							color="#626aef"
							type="info"
							@click="__storeTeamEvaluation(true)"
							:loading="createTaskLoading"
							>Draft</el-button
						>
						<el-button
							class="primary-bg color-white"
							native-type="submit"
							@click="__storeTeamEvaluation(false)"
							:loading="createTaskLoading"
							>Submit</el-button
						>
					</div>
				</el-form>
			</el-card>
			<el-card class="box-card" v-if="is_submitted && !finalSubmission">
				<el-table
					:data="selfRatedData"
					:border="parentBorder"
					style="width: 100%"
					height="350"
					stripe="true"
					:header-cell-style="{ background: '#6770b9', color: 'white' }"
				>
					<el-table-column type="expand">
						<template #default="props">
							<div m="4">
								<!-- <h3>Employee Rating</h3>
								<el-table :data="props.row.employee" :border="childBorder">
									<el-table-column
										label="Employee Rating"
										width="350"
										#default="scope"
									>
										<el-rate
											v-model="scope.row.emp_level"
											:texts="points"
											disabled
											show-text
											size="large"
										/>
									</el-table-column>
									<el-table-column
										label="Employee Comment"
										prop="emp_comment"
									/>
								</el-table> -->
								<el-divider></el-divider>
								<h3>Other Manager Rating</h3>
								<el-table :data="props.row.other_manager" :border="childBorder">
									<el-table-column label="Manager Name" prop="other_mgr_name" />
									<el-table-column
										label="Manager Rating"
										width="350"
										#default="scope"
									>
										<el-rate
											v-model="scope.row.other_mgr_level"
											:texts="points"
											disabled
											show-text
											size="large"
										/>
									</el-table-column>
									<el-table-column
										label="Employee Comment"
										prop="other_mgr_comment"
									/>
								</el-table>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="Question" prop="question" />
					<el-table-column label="Your Rating" width="220" #default="scope">
						<el-rate
							v-model="scope.row.level"
							:texts="points"
							disabled
							show-text
							size="large"
						/>
					</el-table-column>
					<el-table-column label="Employee Rating" width="220" #default="scope">
						<el-rate
							v-model="scope.row.emp_level"
							:texts="points"
							disabled
							show-text
							size="large"
						/>
					</el-table-column>
					<el-table-column label="Your Comment" prop="comment" />
					<el-table-column label="Employee Comment" prop="emp_comment" />
				</el-table>
				<!-- <el-button
					class="btn submit-btn primary-bg color-white"
					native-type="button"
					@click="finalSubmissionForm()"
					v-if="!selfRating"
				>
					Edit Evaluation
				</el-button> -->
				<el-card class="box-card" style="margin-top: 5%">
					<el-form :model="finalForm" label-width="120px">
						<el-form-item label="Mutual Rating">
							<el-rate
								v-model="mutualRating"
								:texts="points"
								disabled
								show-text
								size="large"
							/>
						</el-form-item>
						<el-form-item
							label="Final Comment"
							v-if="isMeetingScheduledByMe && !evalCompleted"
						>
							<el-input v-model="mgrFinalComment" type="textarea" />
						</el-form-item>
						<el-form-item label="Final Comment" v-else>
							<el-input v-model="mgrFinalComment" disabled type="textarea" />
						</el-form-item>
						<el-form-item>
							<el-button
								type="primary"
								@click="meetingCompleted"
								v-if="isMeetingScheduledByMe && !evalCompleted"
								>Save</el-button
							>
							<el-button
								@click="teamMemberPage"
								v-if="isMeetingScheduledByMe && !evalCompleted"
								>Cancel</el-button
							>
						</el-form-item>
					</el-form>
				</el-card>
			</el-card>
			<el-card class="box-card" v-if="finalSubmission">
				<el-form
					class="matrix-form"
					id="matrix-form"
					:rules="rules"
					ref="skillMatrixFormData"
					@submit="$event.preventDefault()"
					@keypress.enter="__storeTeamEvaluation($event)"
				>
					<div class="row" v-for="quest in questions" :key="quest">
						<div class="column" style="width: 45%">
							<div>
								{{ quest.question }}
							</div>
						</div>
						<div class="column" style="text-align: center; margin-right: 40px">
							<el-form-item>
								<el-rate
									v-model="selfEvaluationData.rating[quest.id]"
									:texts="points"
									show-text
									size="large"
								/>
							</el-form-item>
						</div>
						<div class="column" style="width: 33%">
							<el-form-item>
								<el-input
									v-model="selfEvaluationData.comment[quest.id]"
									type="textarea"
									placeholder="Comments"
								/>
							</el-form-item>
						</div>
					</div>
					<!-- Action btn -->
					<div class="flex" style="text-align-last: center">
						<el-button
							color="#626aef"
							type="info"
							@click="__storeTeamEvaluation(true)"
							:loading="createTaskLoading"
							>Save As Draft</el-button
						>
						<el-button
							class="primary-bg color-white"
							native-type="submit"
							@click="__storeTeamEvaluation(false)"
							:loading="createTaskLoading"
							>Final Submission</el-button
						>
					</div>
				</el-form>
			</el-card>
		</div>
	</section>
</template>

<script>
	import TopNavbar from "@/components/TopNavbar.vue";
	import SelfEvaluation from "../services/selfevaluation.js";

	export default {
		name: "Team Evaluation",
		data() {
			return {
				questions: [],
				points: [],
				createTaskLoading: false,
				finalData: null,
				selfEvaluationData: {
					rating: [],
					comment: [],
				},
				selfRatedData: [],
				is_submitted: false,
				avgRating: 0,
				teamMembers: [],
				role: null,
				finalSubmission: false,
				selfRating: true,
				childBorder: true,
				parentBorder: true,
				mutualRating: 0,
				mgrFinalComment: null,
				finalForm: {},
				isMeetingScheduledByMe: false,
				evalCompleted: false,
				profilePic: localStorage.getItem("profile_pic"),
			};
		},

		components: {
			TopNavbar,
		},

		mounted() {
			this.questionList();
			this.pointsMappingList();
			this.fetchEvaluationData();
			this.role = localStorage.getItem("role");
			let userId = this.$route.params.user_id;
			if (userId) {
				this.selfRating = false;
			}
		},

		methods: {
			questionList() {
				let userId = this.$route.params.user_id;
				console.log(userId);
				SelfEvaluation.getQuestionList(userId)
					.then((res) => {
						this.questions = res.data.questions;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			pointsMappingList() {
				SelfEvaluation.getPointsMappingList()
					.then((res) => {
						this.points = res.data.points;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			finalSubmissionForm() {
				this.finalSubmission = true;
				this.selfRatedData.forEach((element, index) => {
					console.log(index);
					this.selfEvaluationData.rating[element.id] = element.level;
					this.selfEvaluationData.comment[element.id] = element.comment;
				});
			},

			__storeTeamEvaluation(draft) {
				let userId = this.$route.params.user_id;
				const arr2 = this.selfEvaluationData.comment;
				const arr3 = this.selfEvaluationData.rating;
				var dataFinal = null;
				var tempData = [];
				arr3.forEach((element, index) => {
					let final = [];
					final[0] = index;
					final[1] = arr2[index];
					final[2] = this.points[element - 1];
					final[3] = element;
					tempData.push(final);
				});
				var filtered = tempData.filter(function (el) {
					return el[2] != undefined;
				});
				this.finalData = filtered;
				var msg = "";
				if (draft) {
					msg = "Are you sure you want to save as draft ";
				} else {
					msg = "Are you sure you want to submit ";
				}
				this.$confirm(msg, {
					confirmButtonText: "OK",
					cancelButtonText: "Cancel",
					type: "info",
				})
					.then(() => {
						dataFinal = this.finalData;
						console.log(dataFinal);
						SelfEvaluation.storeTeamEvaluation(dataFinal, userId, draft)
							.then(() => {
								this.createTaskLoading = false;
								this.selfEvaluationData = {
									rating: [],
									comment: [],
								};
								this.teamMemberPage();
								this.$notify({
									title: "Success",
									message: "Successfully submitted Self Evaulation form",
									type: "success",
									position: "bottom-right",
									duration: 2000,
								});
							})
							.catch((err) => {
								console.log(err);
							});
					})
					.catch(() => {
						// some code
					});
			},

			fetchEvaluationData() {
				let userId = this.$route.params.user_id;
				SelfEvaluation.fetchSelfEvaluationData(userId)
					.then((res) => {
						this.selfRatedData = res.data.finaldata;
						this.avgRating = res.data.ratingData.avgRating;
						this.mutualRating = res.data.ratingData.mutualRating;
						this.isMeetingScheduledByMe = res.data.ratingData.isMeetingScheduled;
						this.evalCompleted = res.data.ratingData.oneToOneMeeting;
						this.mgrFinalComment = res.data.ratingData.mgrFinalComment;
						if (this.selfRatedData.length > 0) {
							this.is_submitted = true;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},

			meetingCompleted() {
				var data = [];
				data = {
					user_id: this.$route.params.user_id,
					mgr_final_comment: this.mgrFinalComment,
				};
				SelfEvaluation.meetingCompleted(data)
					.then((res) => {
						console.log(res);
						this.$notify({
							title: "Success",
							message: "Successfully submitted your data",
							type: "success",
							position: "bottom-right",
							duration: 2000,
						});
						setTimeout(() => {
							this.teamMemberPage();
						}, 1000);
					})
					.catch((err) => {
						console.log(err);
					});
			},

			teamMemberPage() {
				this.$router.push({ name: "Team Members" });
			},
		},
	};
</script>


<style lang="scss" scoped>
	.skill-matrix {
		margin-top: 50px;
		position: absolute;
		left: 50%;
		width: 400px;
		padding: 40px;
		transform: translate(-50%, -50%);
		background: rgba(0, 0, 0, 0.5);
		box-sizing: border-box;
		box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
		border-radius: 10px;
	}

	.container {
		margin-right: 100px;
		margin-left: 100px;
	}

	.column {
		float: left;
	}

	/* Clear floats after the columns */
	.row:after {
		content: "";
		display: table;
		clear: both;
	}
</style>