<template>
	<section class="home-page">
		<header class="header">
			<TopNavbar
				@clicked="sideNavEvent"
				:navFlag="navFlag"
				:name="name"
				:profile_pic="profilePic"
				:role="role"
			/>
		</header>
	</section>

	<section class="skil-matrix">
		<div class="task-intro container">
			<h2 class="heading" style="text-align: center">Evaluate Team Members</h2>
			<el-divider></el-divider>
		</div>
	</section>
	<section>
		<div class="container col-md-12">
			<el-card class="box-card">
				<span v-if="!isAuthorized" style="color: red"
					>Note: Please authorize to Schedule Meeting</span
				>
				<el-row class="mb-4" style="float: right" v-if="!isAuthorized">
					<el-button type="primary" @click="authoriseManager()"
						>Authorize</el-button
					>
				</el-row>
				<br />
				<div style="margin-top: 2%">
					<el-label>Filter </el-label>
					<el-select
						v-model="filterVal"
						placeholder="Filter"
						@change="filter()"
					>
						<el-option
							v-for="item in filterOptions"
							:key="item.value"
							:label="item.label"
							:value="item.value"
							:disabled="item.disabled"
						/>
					</el-select>
					<el-button type="primary" @click="clear()" style="margin-left: 2%"
						>Clear</el-button
					>
					<el-input
						style="margin-left: 4%"
						v-model="search"
						size="large"
						placeholder="Type to search"
						@input="searchByEmpName"
					/>
				</div>
				<!-- <div class="task-intro container">
					<h4 class="heading" style="text-align: left;">My Team Members</h4>
				</div> -->
				<el-table
					:data="teamMembers"
					height="550"
					v-loading="loading"
					element-loading-text="Loading..."
				>
					<el-table-column type="index" :index="indexMethod" width="50" />
					<el-table-column label="Member Name" #default="scope">
						<p>{{ scope.row.name }}</p>
						<small
							>Managers: <b>{{ scope.row.managers }}</b></small
						>
					</el-table-column>
					<el-table-column label="Status" width="350" #default="scope">
						<p>{{ scope.row.appraisalStatus }}</p>
						<br />
						<p
							v-if="
								scope.row.meetingScheduled &&
								scope.row.scheduledById != scope.row.userId &&
								!scope.row.oneToOneMeeting
							"
						>
							Meeting Scheduled By <br /><b>{{
								scope.row.meetingScheduledBy
							}}</b>
						</p>
						<p
							v-if="
								scope.row.meetingScheduled &&
								scope.row.scheduledById == scope.row.userId &&
								!scope.row.oneToOneMeeting
							"
						>
							Meeting Scheduled By You
						</p>
					</el-table-column>
					<el-table-column label="Action" width="350" #default="scope">
						<div v-if="scope.row.isEligible">
							<el-button
								type="primary"
								@click="rate(scope)"
								v-if="!scope.row.oneToOneMeeting && !scope.row.is_rated"
							>
								Rate
							</el-button>
							<el-button
								type="primary"
								@click="rate(scope)"
								v-if="scope.row.is_rated"
							>
								View
							</el-button>
							<el-button
								type="success"
								text
								@click="
									dialogFormVisible = true;
									getAttendee(scope);
								"
								v-if="
									scope.row.is_rated &&
									!scope.row.draft &&
									!scope.row.meetingScheduled &&
									!scope.row.oneToOneMeeting &&
									isAuthorized
								"
							>
								Schedule Meeting
							</el-button>
							<el-button
								type="success"
								disabled
								v-if="!scope.row.is_rated || scope.row.draft"
							>
								Schedule Meeting
							</el-button>
						</div>
						<div v-else>
							<p>Not Eligible For Appraisal</p>
						</div>
					</el-table-column>
					<!-- <el-table-column label="Action" width="350" v-else>
						<el-button type="primary" disabled> Not Eligible {{scope}} </el-button>
					</el-table-column> -->
				</el-table>
				<el-divider></el-divider>

				<div style="text-align: center">
					<el-pagination
						background
						layout="slot, total, prev, pager, next"
						@current-change="handleCurrentChange"
						:page-size="pageSize"
						:total="total"
					>
					</el-pagination>
				</div>

				<el-dialog v-model="dialogFormVisible" title="Schedule Meeting">
					<el-form>
						<div class="block">
							<el-date-picker
								v-model="fromToDate"
								type="datetimerange"
								:shortcuts="shortcuts"
								range-separator="To"
								start-placeholder="Start date"
								end-placeholder="End date"
							/>
						</div>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button @click="dialogFormVisible = false">Cancel</el-button>
							<el-button type="primary" @click="scheduleMeeting"
								>Confirm</el-button
							>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</div>
	</section>
</template>

<script>
	import TopNavbar from "@/components/TopNavbar.vue";
	import EmployeeList from "../services/employeelist.js";
	import moment from "moment";

	export default {
		name: "Team Members",
		data() {
			return {
				teamMembers: [],
				dialogFormVisible: false,
				dialogFormVisible2: false,
				fromToDate: "",
				fromDate: "",
				toDate: "",
				mgrFinalComment: "",
				empId: "",
				attendeeId: "",
				isAuthorized: false,
				authUrl: null,
				managers: null,
				filterVal: "",
				toFilter: [],
				search: "",
				page: 1,
				slNo: 1,
				pageSize: 4,
				loading: true,
				total: 0,
				profilePic: localStorage.getItem("profile_pic"),
				name: localStorage.getItem("username"),
				role: localStorage.getItem("role"),
				indexMethod: (index) => {
					return index + this.slNo;
				},
				shortcuts: [
					{
						text: "Last week",
						value: () => {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							return [start, end];
						},
					},
					{
						text: "Last month",
						value: () => {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							return [start, end];
						},
					},
					{
						text: "Last 3 months",
						value: () => {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							return [start, end];
						},
					},
				],
				filterOptions: [
					{
						value: "Pending",
						label: "Pending",
					},
					{
						value: "Employee Rating Completed",
						label: "Employee Rating Completed",
					},
					{
						value: "Manager Rating Completed",
						label: "Manager Rating Completed",
					},
					{
						value: "Meeting Scheduled",
						label: "Meeting Scheduled",
					},
					{
						value: "Appraisal Completed",
						label: "Appraisal Completed",
					},
				],
			};
		},

		components: {
			TopNavbar,
		},

		created() {
			console.log("FIRST");
			this.getTeamMembers();
		},

		mounted() {
			// this.getEmpManagers();
			this.getAuthroizationUrl();
			const recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute(
				"src",
				"https://assets.calendly.com/assets/external/widget.js"
			);
			document.head.appendChild(recaptchaScript);
		},

		computed: {
			// displayData() {
			// 	this.total = this.teamMembers.length;
			// 	return this.teamMembers.slice(
			// 		this.pageSize * this.page - this.pageSize,
			// 		this.pageSize * this.page
			// 	);
			// },
		},

		methods: {
			getTeamMembers() {
				EmployeeList.teamMemberList()
					.then((res) => {
						this.loading = false;
						this.toFilter = res.data.teamMembers;
						this.total = this.toFilter.length;
						this.teamMembers = this.toFilter.slice(
							this.pageSize * this.page - this.pageSize,
							this.pageSize * this.page
						);
					})
					.catch((err) => {
						console.log(err);
					});
			},

			searchByEmpName() {
				if (this.search.length > 3) {
					EmployeeList.filteredTeamMemberList(this.search)
						.then((res) => {
							console.log(res);
							this.loading = false;
							this.toFilter = res.data.teamMembers;
							this.total = this.toFilter.length;
							this.teamMembers = this.toFilter.slice(
								this.pageSize * this.page - this.pageSize,
								this.pageSize * this.page
							);
						})
						.catch((err) => {
							console.log(err);
						});
				} else if (!this.search) {
					this.getTeamMembers();
				}
			},

			rate(data) {
				let userId = data.row.id;
				this.$router.push({
					name: "Team Evaluation",
					params: { user_id: userId },
				});
			},

			getAttendee(data) {
				this.attendeeId = data.row.id;
			},

			scheduleMeeting() {
				var startEndDate = [];
				var finalData = [];
				this.fromToDate.forEach((element, index) => {
					startEndDate[index] = moment(element.toUTCString()).format(
						"YYYY-MM-DD hh:mm:ss"
					);
				});
				this.fromDate = startEndDate[0];
				this.toDate = startEndDate[1];
				finalData = {
					start: this.fromDate,
					end: this.toDate,
					attendee_id: this.attendeeId,
				};
				console.log(finalData);
				EmployeeList.calendarInvitation(JSON.stringify(finalData))
					.then((res) => {
						this.dialogFormVisible = false;
						this.$notify({
							title: "Success",
							message: "Successfully scheduled the meeting",
							type: "success",
							position: "bottom-right",
							duration: 2000,
						});
						setTimeout(() => {
							this.getTeamMembers();
						}, 1000);
						console.log(res);
					})
					.catch((err) => {
						console.log(err);
					});
			},

			empData(data) {
				this.empId = data.id;
			},

			meetingCompleted() {
				console.log(this.empId);
				var data = [];
				data = {
					user_id: this.empId,
					mgr_final_comment: this.mgrFinalComment,
				};
				EmployeeList.meetingCompleted(data)
					.then((res) => {
						this.dialogFormVisible2 = false;
						console.log(res);
					})
					.catch((err) => {
						console.log(err);
					});
			},

			getAuthroizationUrl() {
				EmployeeList.authrorizationUrl()
					.then((res) => {
						this.authUrl = res.data.auth_url;
						this.isAuthorized = res.data.isAuthorized;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			getEmpManagers() {
				EmployeeList.empMgrs()
					.then((res) => {
						this.authUrl = res.data.auth_url;
						this.isAuthorized = res.data.isAuthorized;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			filter() {
				EmployeeList.filterTeamMembers(this.filterVal)
					.then((res) => {
						this.toFilter = res.data.teamMembers;
						this.total = this.toFilter.length;
						this.teamMembers = this.toFilter.slice(
							this.pageSize * this.page - this.pageSize,
							this.pageSize * this.page
						);
					})
					.catch((err) => {
						console.log(err);
					});
			},

			authoriseManager() {
				window.open(this.authUrl, "_blank");
			},

			clear() {
				this.filterVal = "";
				this.getTeamMembers();
			},

			handleCurrentChange(val) {
				this.page = val;
				if (this.page > 1) {
					this.slNo = this.page * 4 - 3;
				} else {
					this.slNo = 1;
				}
				this.teamMembers = this.toFilter.slice(
					this.pageSize * this.page - this.pageSize,
					this.pageSize * this.page
				);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.skill-matrix {
		margin-top: 50px;
		position: absolute;
		left: 50%;
		width: 400px;
		padding: 40px;
		transform: translate(-50%, -50%);
		background: rgba(0, 0, 0, 0.5);
		box-sizing: border-box;
		box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
		border-radius: 10px;
	}

	.container {
		margin-right: 100px;
		margin-left: 100px;
	}

	.column {
		float: left;
	}

	/* Clear floats after the columns */
	.row:after {
		content: "";
		display: table;
		clear: both;
	}
	.el-button--text {
		margin-right: 15px;
	}
	.el-select {
		width: 300px;
	}
	.el-input {
		width: 300px;
	}
	.dialog-footer button:first-child {
		margin-right: 10px;
	}
</style>