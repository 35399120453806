<template>
	<section class="home-page">
		<header class="header">
			<TopNavbar
				@clicked="sideNavEvent"
				:navFlag="navFlag"
				:name="username"
				:profile_pic="profilePic"
				:role="role"
			/>
		</header>
	</section>

	<section class="skil-matrix">
		<div class="task-intro container">
			<div style="float: right">
				<el-button type="primary" icon="el-icon-user" @click="skills"
					>Back</el-button
				>
			</div>
			<h2 class="heading" style="text-align: center">Add Skills</h2>
			<el-divider></el-divider>
		</div>
		<!-- Action btn -->
		<div class="container">
			<section class="task-list">
				<el-card class="box-card is-always-shadow" shadow="always">
					<div>
						Note:
						<ul>
							<li><b>Beginner</b>: Basic Knowledge/Learning</li>
							<li>
								<b>Intermediate</b>: At Least 1 project or 2 Completed POC
							</li>
							<li><b>Advanced</b>: Worked on at least 2 projects</li>
							<li>
								<b>Expert</b>: Min. 3+ year and 3 projects exp in the same tech
							</li>
						</ul>
					</div>
					<el-divider></el-divider>
					<div class="new-task-covers" style="margin:none;!important">
						<el-form
							class="matrix-form"
							id="matrix-form"
							:model="skillMatrixFormData"
							:rules="rules"
							:inline="true"
							ref="skillMatrixFormData"
							@submit="$event.preventDefault()"
							@keypress.enter="__storeSkillMatrix($event)"
						>
							<CreateSkillMatrix
								:matrixData="matrixData"
								:allTechs="allTechs"
								:frameworkData="frameworkData"
								:allFrameworks="allFrameworks"
								:dbData="dbData"
								:allDbs="allDbs"
								:ideData="ideData"
								:allIde="allIde"
								:allVersionTools="allVersionTools"
								:versionToolsData="versionToolsData"
								:allOs="allOs"
								:osData="osData"
								:allWebApis="allWebApis"
								:webApiData="webApiData"
								:skillLevels="skillLevels"
							/>
							<label for="" style="font-size: initial"
								><b>Other skillset or certifications</b></label
							>
							<el-input
								v-model="otherDetails"
								maxlength="500"
								placeholder="Please add other skillset or certifications ex:Postman, Google Maps API, G Suite"
								show-word-limit
								type="textarea"
							/>
							<!-- Action btn -->
							<div class="action-btn" style="margin-top: 5%">
								<el-button
									class="btn submit-btn primary-bg color-white"
									native-type="submit"
									@click="__storeSkillMatrix($event)"
									:loading="createTaskLoading"
								>
									{{ buttonText }}
								</el-button>
							</div>
						</el-form>
					</div>
				</el-card>
			</section>
		</div>
	</section>
</template>

<script>
	import TopNavbar from "@/components/TopNavbar.vue";
	import CreateSkillMatrix from "@/components/SkillMatrix/CreateSkillMatrix.vue";
	import SkillMatrix from "../services/skillmatrix.js";

	export default {
		name: "Add Skill Matrix",

		data() {
			return {
				createTaskLoading: false,
				navFlag: false,
				index: 0,
				name: null,
				role: null,
				valid: true,
				allTechs: [],
				allDbs: [],
				allFrameworks: [],
				allIde: [],
				allOs: [],
				allWebApis: [],
				allVersionTools: [],
				otherDetails: null,
				buttonText: "Create Skill Matrix",
				skillLevels: [],
				techName: null,
				isEdit: false,
				selectedSkillId: null,
				selectedDesc: null,
				selectedSkillMatrixData: {},
				profilePic: localStorage.getItem("profile_pic"),
				username: localStorage.getItem("username"),
				skillMatrixFormData: {
					tech_id: "",
					level: "",
					f_id: "",
					f_level: "",
					db_id: "",
					db_level: "",
					ide_id: "",
					ide_level: "",
					vt_id: "",
					vt_level: "",
					os_id: "",
					os_level: "",
					web_api_id: "",
					web_api_level: "",
				},
				matrixData: [{ tech_id: null, level: null }],
				frameworkData: [{ f_id: "", f_level: "" }],
				dbData: [{ db_id: "", db_level: "" }],
				ideData: [{ ide_id: "", ide_level: "" }],
				versionToolsData: [{ vt_id: "", vt_level: "" }],
				osData: [{ os_id: "", os_level: "" }],
				webApiData: [{ web_api_id: "", web_api_level: "" }],
			};
		},

		components: {
			TopNavbar,
			CreateSkillMatrix,
		},

		mounted() {
			this.getAllTechs();
			this.getSkillLevels();
		},

		methods: {
			__storeSkillMatrix(ev) {
				console.log(ev);
				// var valid =
				// this.$refs["matrixData"].validate((valid) => {
				if (!this.createTaskLoading) {
					this.createTaskLoading = true;
					var data = [];
					if (this.isEdit) {
						this.matrixData = [
							{
								tech_id: this.matrixData.tech_id,
								level: this.matrixData.level,
							},
						];
						data = JSON.stringify(this.matrixData);
					} else {
						data = {
							matrixData: this.matrixData,
							frameworkData: this.frameworkData,
							dbData: this.dbData,
							ideData: this.ideData,
							versionToolsData: this.versionToolsData,
							osData: this.osData,
							webApiData: this.webApiData,
							otherDetails: this.otherDetails,
						};
					}
					let errorIndex = [];
					this.matrixData.forEach(function (data, index) {
						if (data.tech_id && !data.level) {
							errorIndex.push(++index);
						}
					});
					if (errorIndex.length) {
						this.$notify({
							title: "Error",
							message: "Please fill all the details in Skill: " + errorIndex,
							type: "error",
							position: "bottom-right",
							duration: 5000,
						});
						this.createTaskLoading = false;
					} else {
						SkillMatrix.storeSkillMatrix(JSON.stringify(data))
							.then(() => {
								console.log("create skill matrix is called");
								this.createTaskLoading = false;
								this.resetmodal();
								this.isEdit = false;
								this.buttonText = "Create Skill Matrix";
								this.$notify({
									title: "Success",
									message: "Successfully submitted Skill form",
									type: "success",
									position: "bottom-right",
									duration: 2000,
								});

								setTimeout(() => {
									this.$router.push({ name: "Skill Matrix" });
								}, 2000);
							})
							.catch((err) => {
								console.log(err);
							});
					}
				} else {
					return;
				}
				// });
			},

			resetmodal() {
				document.getElementById("matrix-form").reset();
				this.matrixData = [{ tech_id: "", level: "" }];
				this.frameworkData = [{ f_id: "", f_level: "" }];
				this.dbData = [{ db_id: "", db_level: "" }];
				this.ideData = [{ ide_id: "", ide_level: "" }];
				this.versionToolsData = [{ vt_id: "", vt_level: "" }];
				this.osData = [{ os_id: "", os_level: "" }];
				this.webApiData = [{ web_api_id: "", web_api_level: "" }];
				this.otherDetails = null;
			},

			getAllTechs() {
				SkillMatrix.getAllTechs()
					.then((res) => {
						this.allTechs = res.data.data.tech;
						this.allFrameworks = res.data.data.frameworks;
						this.allDbs = res.data.data.dbs;
						this.allIde = res.data.data.ide;
						this.allVersionTools = res.data.data.versionTools;
						this.allOs = res.data.data.operatingSystems;
						this.allWebApis = res.data.data.webApis;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			getSkillLevels() {
				SkillMatrix.getAllSkillLevels()
					.then((res) => {
						this.skillLevels = res.data.all_levels;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			skills() {
				this.$router.push({ name: "Skill Matrix" });
			},
		},
	};
</script>


<style lang="scss" scoped>
	// .skill-matrix {
	// 	margin-top: 50px;
	// 	position: absolute;
	// 	left: 50%;
	// 	width: 400px;
	// 	padding: 40px;
	// 	transform: translate(-50%, -50%);
	// 	background: rgba(0, 0, 0, 0.5);
	// 	box-sizing: border-box;
	// 	box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
	// 	border-radius: 10px;
	// }

	.container {
		margin-right: 100px;
		margin-left: 100px;
	}

	table {
		font-family: arial, sans-serif;
		border-collapse: collapse;
		width: 100%;
	}

	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #ccc;
		margin: 1em 0;
		padding: 0;
	}

	td,
	th {
		border: 1px solid #dddddd;
		text-align: left;
		padding: 8px;
	}

	tr:nth-child(even) {
		background-color: #dddddd;
	}

	.button {
		width: 180px;
		float: right;
		margin-bottom: 10px;
	}

	// .main-div {
	// 	margin: 1rem;
	// 	padding: 2rem 2rem;
	// 	text-align: center;
	// }

	// .side-nav {
	// 	display: flex;
	// 	position: relative;
	// 	// justify-content: center;
	// 	flex-direction: column;

	// 	@media (max-width: 960px) {
	// 		// display: none;
	// 		justify-content: flex-start;
	// 		position: absolute;
	// 		z-index: 1000;
	// 	}
	// }
	// .new-task-cover {
	// 	width: 500px;
	// 	margin: auto;

	// 	@media (max-width: 960px) {
	// 		width: 340px;
	// 		display: grid;
	// 		place-content: center;
	// 		margin: auto;
	// 	}
	// }

	// .task-list {
	// 	overflow: hidden;
	// }

	// .box-card {
	// 	// width: 550px;
	// }
</style>